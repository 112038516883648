<template>
    <div class="option_declaration">
        <h3>Déclaration template:</h3>
        <br/>
        <!--<vs-divider/>-->
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
        <br/>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <span></span>
                <vs-button color="success" type="filled" v-on:click="save()" >Enregistrer</vs-button>
            </vs-col>
        </vs-row>
        <br/>
    </div>
</template>

<style lang="scss">
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
    data(){
        return{
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
                // The configuration of the editor.
            }
        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
			//refresh todo
            this.Load();
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            
        }
    },
    methods:
    {
        Load()
        {
            this.$srvApi.Req('get', 'studio/declaration')
            .then(( rep )=>
            {
                this.editorData = rep.data;
            });
        },

        save()
        {
            this.$srvApi.Req('put', 'studio/declaration', {
                'html': escape(this.editorData)
            })
            .then(()=>
            {
                alert("Enregistré");
                //console.log( rep );
                autoRefresh.refresh();
            });
        }
    }
};

</script>